/* Container for the entire login page */
.pageContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #E0E1EB; /* Background color similar to the loadxmlpage */
    min-height: 100vh; /* Full viewport height */
  }
  
  /* Form container styles */
  .formContainer {
    background-color: #ffffff; /* White background */
    padding: 40px; /* More padding for a centered form */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px; /* Limit the width of the form */
    width: 100%; /* Ensure it is responsive */
    box-sizing: border-box;
  }
  
  /* Header subtitle styling */
  .headerSubtitle {
    font-size: 24px;
    font-family: 'DM Serif Text', serif; /* Consistent font style */
    color: #292B3D; /* Darker color for the header */
    margin-bottom: 0px; /* Space below the title */
    text-align: center;
  }
  
  /* Error message styling */
  .error {
    color: #EB4755; /* Error text color */
    background-color: #ffe6e6; /* Light red background */
    padding: 10px;
    margin-top: 20px;
    border: 1px solid #EB4755; /* Border matching the text color */
    border-radius: 5px;
    width: 100%; /* Full width */
    text-align: center; /* Center the text */
    box-sizing: border-box; /* Ensure padding is included in width */
  }
  
  /* Google Login button container */
  .googleButtonContainer {
    width: 100%; /* Full width */
    margin-top: 20px; /* Space above the button */
    display: flex;
    justify-content: center;
  }
  
  /* Google Login button styling */
  .googleButton {
    width: 100%; /* Full width of the container */
    max-width: 200px; /* Limit the max width */
  }
  
  .infoText {
    font-family: 'DM Sans', sans-serif; /* Use DM Sans font */
    font-size: 14px; /* Adjust font size as needed */
    color: #555555; /* Set a neutral color */
    margin-top: 10px; /* Space above the paragraph */
    text-align: center; /* Center-align the text */
    line-height: 1.5; /* Improve readability */
  }