.pageContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #E0E1EB; /* Set the background color here */
  min-height: 100vh; /* Ensure it covers the entire viewport */
}

.topBar {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Keep space between header text and the image */
  padding-left: 20px;
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 20px;
  background-color: #ffffff;
  color: #4758EB;
  font-family: 'Lexend Deca', sans-serif;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.headerTextContainer {
  display: flex;
  flex-direction: column;
  margin-left: 0px; /* Space between the logout button and the text */
  flex-grow: 1; /* Ensure the text container takes up available space */
}

.feedViewerVersion {
  font-size: 16px; /* Small letters */
  font-family: 'Lexend Deca', sans-serif; /* Font for "Feed Viewer 2.0" */
  font-weight: 600;
  color: #4758EB; /* Color for "Feed Viewer 2.0" */
}

.headerSubtitle {
  font-size: 24px;
  font-family: 'DM Serif Text', serif; /* Font for "Get ready to crunch some feeds!" */
  color: #292B3D; /* Color for "Get ready to crunch some feeds!" */
}

.headerImage {
  height: 100px;
  width: auto;
  padding-top: 20px;
  object-fit: contain;
  margin-left: auto; /* Keeps the image aligned to the right */
}


.logo {
    height: 20px;
    margin-right: 10px;
}

.feedViewerText {
    padding-top: 2px;
    font-size: 20px;
    font-weight: 500;
}

.content {
    padding: 20px;

}

.selectedColumn {
    background-color: #cce5ff;
    
}


.error {
    color: #EB4755;
    background-color: #ffe6e6;
    padding: 10px;
    margin-top: 20px;
    border: 1px solid #EB4755;
    border-radius: 5px;
  }


  .formContainer {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between elements */
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 100%; /* Ensure it doesn't overflow the screen */
    overflow-x: hidden; /* Prevent horizontal overflow */
    box-sizing: border-box; /* Include padding and border in width */
}
  
.inputGroup {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 0; /* Prevent overflow */
}
  
  .inputGroup label {
    font-size: 12px;
    margin-bottom: 5px;
  }
  
  .inputField,
  .fileInput {
      padding: 8px 12px;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 14px;
      width: 100%;
      box-sizing: border-box;
  }
  

  /* Additional paragraph styling */
.infoText {
  font-family: 'DM Sans', sans-serif; /* Use DM Sans font */
  font-size: 14px; /* Adjust font size as needed */
  color: #555555; /* Set a neutral color */
  margin-top: 20px; /* Space above the paragraph */
  text-align: center; /* Center-align the text */
  line-height: 1.5; /* Improve readability */
}

.loadButton {
    width: 50px;
    height: 50px;
    background-color: #94D1A8;
    color: #fff;
    border: none;
    border-radius: 50%; /* Make it circular */
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px; /* Optional: Add some space before the button */
}

  
  .loadButton:disabled {
    background-color: #E0EBE4;
    cursor: not-allowed;
  }
  
  .loadButton:hover:not(:disabled) {
    background-color: #5CD685;
  }

  .auditButton {
    height: 50px; /* Same height as the load button */
    padding: 0 20px; /* Adjust padding to keep the text centered */
    background-color: #848EE0;
    color: #fff;
    border: none;
    border-radius: 25px; /* Rounded edges for a pill shape */
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px; /* Space between the buttons */
    white-space: nowrap; /* Ensure text stays in one line */
    box-sizing: border-box; /* Include padding and border in height/width */
}

.auditButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.auditButton:hover:not(:disabled) {
    background-color: #6577C7;
}


  
  @media (min-width: 768px) {
    .formContainer {
        flex-wrap: nowrap; /* Ensure no wrapping on larger screens */
    }
    
    .inputGroup {
        flex-direction: row;
        align-items: center;
        gap: 10px;
    }
  
    .fileInput {
        width: auto;
    }
}
  
  .itemTagInput {
    flex-grow: 1; /* Tag input takes the least space */
    max-width: 100px; /* Limit maximum width */
}
  
  .feedUrlInput {
    flex-grow: 6; /* Allow the Feed URL input to take more space */
    min-width: 300px; /* Ensure minimum width for smaller screens */
}

.fileInput {
  flex-grow: 2; /* Allow the file input to take significant space */
  min-width: 250px; /* Ensure minimum width for smaller screens */
}

.loadButtonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 0; /* Button takes its own space, not growing */
}


  /* General list styles */
  .columnList {
    user-select: none; /* Prevents text selection */
    list-style-type: none;
    padding: 0;
    height: 300px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    background-color: #e0e1eb20;
}
  
  /* Styles for each column item */
  .columnItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;
    border-radius: 4px;
    cursor: move;
    transition: background-color 0.2s;
}


.selectedColumnItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;
    border-radius: 4px;
    cursor: move;
    /* No background-color change on hover */
}


.columnItem:hover {
    background-color: #e6f0ff; /* Highlight on hover */
}
  
.deleteIcon {
    color: #ffffff; /* Set the X icon color to white */
    font-size: 18px;
    padding: 0px; /* Add some padding to center the X in the circle */
    margin: 0px;
    cursor: pointer;
    background-color: #E0858C; /* Set the background color to red */
    border-radius: 50%; /* Make it a circle */
    display: inline-block; /* Ensure it sizes correctly */
    line-height: 18px; /* Center the X vertically */
    width: 20px; /* Set a fixed width for the circle */
    height: 20px; /* Set a fixed height for the circle */
    text-align: center; /* Center the text horizontally */
    box-sizing: border-box; /* Ensure padding is included in the element's dimensions */
    border: none; /* Remove any default border */
    outline: none; /* Remove any outline that might be applied */
}
  
.deleteIcon:hover {
  background-color: #EB4755; /* Darker red on hover */
}








  .selectButton {
    width: 50px;
    height: 50px;
    background-color: #848EE0;
    color: #fff;
    border: none;
    border-radius: 50%; /* Make it circular */
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 20px; /* Add space between the lists */
  }

  .selectButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .selectButton:hover:not(:disabled) {
    background-color: #6577C7;
  }

  .tableContainer th {
    padding: 8px; /* Ensure padding is consistent */
    text-align: left;
    vertical-align: middle; /* Ensures the text stays in the middle vertically */
    background-color: #848EE0;
    color: white;
    font-size: 16px;
    border-bottom: 1px solid #ccc; /* Optional: a border for clarity */
    white-space: nowrap;
  }
  



.tableWrapper {
  display: block;
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: hidden; /* Prevent vertical overflow */
  padding-bottom: 15px;
  scrollbar-color: #848EE0 #E0E1EB; /* Custom scrollbar color */
  scrollbar-width: thin; /* Thin scrollbar */
}

.tableWrapper::-webkit-scrollbar {
  height: 10px;
}

.tableWrapper::-webkit-scrollbar-thumb {
  background-color: #848EE0; /* Custom color */
  border-radius: 5px;
}

.tableWrapper::-webkit-scrollbar-track {
  background-color: #E0E1EB;
}

.tableContainer table {
  margin-top: 20px;
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed; /* Fixed layout for consistent column width */
  margin-bottom: 0;
  font-family: 'DM Sans', sans-serif; /* Use DM Sans font */
  border-radius: 8px; /* Rounded corners for the table */
  overflow: hidden; /* Ensure the rounded corners are visible */
}

.tableContainer th,
.tableContainer td {
    padding: 15px; /* Increase padding for better spacing */
    text-align: left;
    border-bottom: 1px solid #ccc;
    font-size: 14px;
    color: #3c4858; /* Set a consistent text color */
    background-color: #ffffff; /* White background for cells */
    width: 200px;
    word-wrap: break-word;
    word-break: break-word;
    text-overflow: ellipsis;
}

.tableContainer th {
  background-color: #848EE0;
  color: white;
  font-size: 16px; /* Slightly larger font size for headers */
  position: sticky !important;
  top: 0;
  z-index: 2;
  text-align: left;
  user-select: none;
  padding: 15px; /* Matching padding */
}

.tableContainer tr:nth-child(even) {
    background-color: #f9f9f9; /* Alternate row colors */
}

.tableContainer tr:hover {
    background-color: #e6f0ff; /* Highlight row on hover */
}

.paginationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding-top: 8px;
  padding-bottom: 4px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  position: sticky;
  bottom: 0;
  left: 50%; /* Position the left edge of the container at the center of the screen */
  transform: translateX(-50%); /* Shift the container left by 50% of its width to center it */
  width: 50%;
  border-radius: 8px; /* Consistent border radius */
  max-width: 100%; /* Ensure it doesn’t overflow the screen width */
}

.paginationContainer p {
    margin: 0px;
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    color: #333;
}

.paginationContainer button {
  background-color: #848EE0;
  color: #fff;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
}

.paginationContainer input[type="text"] {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  width: 50px; /* Adjust the width to fit the content */
  box-sizing: border-box;
  text-align: center; /* Center-align the text inside */
}


.paginationContainer button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.paginationContainer button:hover:not(:disabled) {
    background-color: #6577C7;
}

.paginationContainer select {
    padding: 4px 6px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    font-family: 'DM Sans', sans-serif;
    color: #333;
    margin-right: 20px;
    cursor: pointer;
}

.mainContent {
  flex: 1;
  padding: 20px;
  background-color: #F5F6FA; /* Main content background color */
}

.columnSelectionContainer {
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #ffffff; /* White background */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  
}


.columnSectionTitle {
  color: #8A92A6; /* Set text color to #8A92A6 */ /* Make the text bold */
  font-size: 16px; /* Ensure the text is in paragraph size */
  margin-bottom: 10px; /* Optional: Adjust margin for spacing */
}


.popupContent {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 80%;
  height: 80%;
  max-width: 1200px;
  overflow-y: auto;
  position: relative;
  font-family: 'DM Sans', sans-serif; /* Ensure all text uses DM Sans */
}

.itemDetails {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Space between rows */
}

.itemDetailRow {
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  border-bottom: 1px solid #ececec; /* Divider between sections */
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.itemDetailRow:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.itemDetailRow strong {
  color: #3c4858;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 5px;
}

.itemDetailRow span {
  color: #333333;
  font-size: 16px;
}


.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popupContent {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 80%;
  height: 80%;
  max-width: 1200px;
  overflow-y: auto;
  position: relative;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.imageSection {
  display: flex;
  gap: 10px; /* Space between images */
  margin-bottom: 10px; /* Space below the images */
}

.imageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popupImage {
  max-width: 150px;
  max-height: 150px;
  width: auto; /* Adjust the width based on the image's aspect ratio */
  height: auto; /* Adjust the height based on the image's aspect ratio */
  object-fit: contain; /* Make sure the image fits within the box */
  display: block; /* Remove any inline spacing */
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margin */
}

  

.imageLabel {
  margin-top: 5px;
  font-size: 14px;
  color: #555;
  font-family: 'DM Sans', sans-serif; /* Ensure the label uses DM Sans */
}

.itemDetails {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.itemDetailRow {
  display: flex;
  justify-content: space-between;
  word-wrap: break-word;
}

.popupButton {
  background-color: #848EE0; /* Button background color */
  color: #fff; /* Icon color */
  border: none; /* Remove border */
  padding: 10px; /* Adjust padding to make the button small */
  border-radius: 50%; /* Make the button circular */
  cursor: pointer; /* Change cursor to pointer */
  transition: background-color 0.3s; /* Smooth transition for hover */
  display: flex; /* Flexbox to center the icon */
  justify-content: center; /* Center the icon horizontally */
  align-items: center; /* Center the icon vertically */
  width: 40px; /* Set width to make it a circle */
  height: 40px; /* Set height to make it a circle */
  font-size: 0; /* Remove any default font size */
}


.popupButton:hover {
  background-color: #6577C7; /* Darker shade on hover */
}

.sectionHeader {
  font-size: 14px; /* Ensure the header is the same size as the other headers */
  color: #3c4858; /* Consistent color with other headers */
  font-weight: bold;
  margin-bottom: 10px;
  font-family: 'DM Sans', sans-serif; /* Ensure the header uses DM Sans */
}

.actionColumn {
  position: sticky;
  left: 0;
  background-color: #fff;
  z-index: 3;
  min-width: 40px;
  text-align: center;
  user-select: none;
  padding: 0;
  max-width: 40px;
}

.productPageContainer {
  display: flex;
  align-items: center;
  margin-top: 10px; /* Optional: Add some space above the button */
}

.productPageButton {
  background-color: #848EE0; /* Button background color */
  color: #fff; /* Icon color */
  border: none; /* Remove border */
  padding: 10px; /* Adjust padding to make the button small */
  border-radius: 50%; /* Make the button circular */
  cursor: pointer; /* Change cursor to pointer */
  transition: background-color 0.3s; /* Smooth transition for hover */
  display: flex; /* Flexbox to center the icon */
  justify-content: center; /* Center the icon horizontally */
  align-items: center; /* Center the icon vertically */
  width: auto; /* Adjust the width based on content */
  height: 40px; /* Set height to make it a circle */
  margin-right: 10px; /* Add space between the button and text */
}

  .productPageButton:hover {
    background-color: #6577C7; /* Darker shade on hover */
  }
  
  .productPageIcon {
    font-size: 16px; /* Adjust icon size */
  }
  
  .productPageText {
    font-size: 14px;
    color: #3c4858; /* Same color as headers for consistency */
    font-family: 'DM Sans', sans-serif; /* Ensure the text uses DM Sans */
    text-decoration: none; /* Remove underline from link */
  }
  
  .productPageText:hover {
    text-decoration: underline; /* Optional: Underline text on hover */
  }


  .filterContainer {
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-top: 1px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
  
.filterSection h3 {
  color: #3c4858;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  font-family: 'DM Sans', sans-serif;
}
  
.filterRow {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;
  flex-wrap: wrap; /* Allow wrapping if the container is too small */
}

  
.filterLogicDropdown, .filterDropdown, 
.filterInput {
    padding: 8px 10px; /* Consistent padding for all inputs */
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
    font-family: 'DM Sans', sans-serif;
    box-sizing: border-box;
    flex-grow: 1; /* Allow inputs to grow and fill the available space */
    min-width: 150px; /* Set a minimum width to prevent fields from becoming too small */
}

  .filterLogicSection {
    flex-basis: 10%; /* Adjust width for AND/OR dropdown */
}

.filterLogicDropdown {
  width: 10%; /* Ensure it takes up full space allocated by the flex container */
  min-width: 80px;
}

.filterDropdown {
    flex-basis: 25%; /* Set width for dropdowns */
}

.filterInput {
    flex-basis: 30%; /* Set width for input fields */
}

  
  .orderBySection {
    margin-top: 20px;
    display: flex;
    align-items: center; /* Align items to the center vertically */
    justify-content: space-between; /* Space between the selects and buttons */
  }
  
  .orderBySection {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap; /* Allow wrapping if needed */
    gap: 10px; /* Add gap between elements */
}

.orderBySection #orderBy,
.orderBySection #orderDir {
    flex-grow: 1; /* Allow dropdowns to fill the available space */
    min-width: 150px; /* Set a minimum width */
}

  .filterButtonsWrapper {
    display: flex;
    justify-content: flex-end; /* Align buttons to the right */
    gap: 10px; /* Space between the buttons */
    flex-shrink: 0; /* Prevent the buttons from shrinking */
    margin-left: auto; /* Push the buttons to the far right */
  }
  


  .orderBySection select {
    padding: 8px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    background-color: #f9f9f9;
    font-family: 'DM Sans', sans-serif;
    box-sizing: border-box;
}
  
  .filterButtons {
    margin-top: 20px;
    display: flex;
    gap: 10px; /* Space between the buttons */
  }
  
  .addFilterButton {
    width: 40px;
    height: 40px;
    padding-left: 7px;
    background-color: #94D1A8;
    color: #ffffff;
    border: none;
    border-radius: 50%;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;
  }
  
  .addFilterButton:hover {
    background-color: #5CD685; /* Darker green on hover */
  }
  
  .applyFilterButton {
    background-color: #848EE0;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: background-color 0.3s;
  }
  
  .applyFilterButton:hover {
    background-color: #6577C7; /* Darker background on hover */
  }


  .filterDeleteButton {
    color: #ffffff; /* Set the X icon color to white */
    font-size: 18px;
    padding: 0px; /* Add some padding to center the X in the circle */
    margin: 0px;
    cursor: pointer;
    background-color: #E0858C; /* Set the background color to red */
    border-radius: 50%; /* Make it a circle */
    display: inline-block; /* Ensure it sizes correctly */
    line-height: 18px; /* Center the X vertically */
    width: 20px; /* Set a fixed width for the circle */
    height: 20px; /* Set a fixed height for the circle */
    text-align: center; /* Center the text horizontally */
    box-sizing: border-box; /* Ensure padding is included in the element's dimensions */
    border: none; /* Remove any default border */
    outline: none; /* Remove any outline that might be applied */
}
  
.filterDeleteButton:hover {
  background-color: #EB4755; /* Darker red on hover */
}

.filterButton {
  width: 50px;
  height: 50px;
  background-color: #ffffff;
  color: #4758EB;
  border: none;
  border-radius: 50%; /* Make it circular */
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: subtle shadow for better visual */
  position: fixed;
  bottom: 20px;
  right: 20px; /* Position it at the bottom-right corner */
  z-index: 1000; /* Ensure it appears above other elements */
}

.filterButton:hover {
  background-color: #f0f0f0; /* Slightly darker on hover */
}

.hiddenFilterContainer {
  display: none; /* Hide the filter container initially */
}

.visibleFilterContainer {
  display: block; /* Show the filter container when toggled */
}


.downloadButton {
  background-color: #ffffff00 !important; /* Set the background color to #E0E1EB */
  color: #848EE0 !important; /* Set the icon color to #292B3D */
  border: none;
  padding: 10px;
  border-radius: 50%; /* Make the button circular */
  cursor: pointer;
  transition: color 0.3s !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px; /* Adjust width and height as needed */
  height: 50px;
}

.downloadButton:hover {
  color: #4758EB !important;
}


.logoutButton {
  background-color: #E0858C; /* Same background color as the back button */
  color: #ffffff; /* White icon color */
  border: none;
  padding: 10px;
  border-radius: 50%; /* Make the button circular */
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px; /* Set width to make it a circle */
  height: 40px; /* Set height to make it a circle */
  margin-right: 20px; /* Add some space between the logout button and the header text */
}

.logoutButton:hover {
  background-color: #EB4755; /* Darker background on hover */
}
