.pageContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #E0E1EB;
  min-height: 100vh;
}

.topBar {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensure space between title and image */
  padding-left: 20px;
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 20px;
  background-color: #ffffff;
  color: #4758EB;
  font-family: 'Lexend Deca', sans-serif;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  gap: 10px; /* Space between the back button and the title */
}





.headerTextContainer {
  display: flex;
  flex-direction: column;
  margin-left: 10px; /* Space between back button and text */
  flex-grow: 1; /* Ensures the text container takes up available space */
}



.feedViewerVersion {
  font-size: 16px;
  font-family: 'Lexend Deca', sans-serif;
  font-weight: 600;
  color: #4758EB;
}

.headerSubtitle {
  font-size: 24px;
  font-family: 'DM Serif Text', serif;
  color: #292B3D;
}

.headerImage {
  height: 100px;
  width: auto;
  padding-top: 20px;
  object-fit: contain;
  margin-left: auto; /* Ensures the image stays on the right */
}


.content {
  margin-top: 20px;
  width: 100%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}


.auditTitle {
  font-size: 20px;
  font-family: 'DM Serif Text', serif;
  color: #292B3D;
  margin-bottom: 15px;
}

.auditTable {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  font-family: 'Lexend Deca', sans-serif;
  table-layout: fixed; /* Ensures equal width for all columns */
}

.auditTable th,
.auditTable td {
    padding: 10px;
    border: 1px solid #ddd;
}

.auditTable th {
    background-color: #f8f8f8;
    color: #000000;  /* Change header text color to black */
    font-weight: bold;
}

.auditTable td {
    background-color: #ffffff;
    color: #292B3D;
}

.auditTable td span {
  font-size: 12px;
  color: #888;
  display: block;
  margin-top: 5px;
}

.auditTable td:nth-child(2) {  /* Empty */
  background-color: #ffcccc;  /* Light red */
}

.auditTable td:nth-child(3) {  /* <25 */
  background-color: #ffe6e6;  /* Red */
}

.auditTable td:nth-child(4) {  /* 25-49 */
  background-color: #ffedcc;  /* Orange */
}

.auditTable td:nth-child(5) {  /* 50-74 */
  background-color: #ccf2e5;  /* Light teal */
}

.auditTable td:nth-child(6) {  /* >75 */
  background-color: #b3e6cc;  /* Light green */
}

.divider {
  border: none;           /* Remove default border */
  border-top: 1px solid #ccc;  /* Custom border style */
  margin: 30px 0;          /* Spacing around the divider */
}


.auditTable .missing-attribute {
  background-color: #f9f9f9;
}

.auditTable .missing-attribute-percentage {
  font-weight: bold;
  color: #ff4d4d;
}

.error {
  color: red;
  font-weight: bold;
  font-family: 'Lexend Deca', sans-serif;
}

.languageSelector {
  display: flex;
  align-items: center;
  margin-right: 10px; /* Adjust space between the language dropdown and the input field */
}

.languageSelector label {
  margin-right: 10px;
  width: 150px; /* Ensure this matches the width of other labels */
}

.languageSelector select {
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.configurationContainer,
.auditResult {
  width: calc(100% - 40px); /* Subtracting 40px to account for 20px padding on both sides */
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}


.colorInputContainer {
  display: flex;
  align-items: center;
  flex: 1; /* Allow the input field to take the remaining space */
}

.colorInputContainer label {
  margin-right: 10px;
  font-weight: bold;
}

.colorInput {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}


.applyFilterButton {
  background-color: #848EE0;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 14px;
  width: 100px; /* Set a fixed width that works for both the icon and spinner */
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: background-color 0.3s;
}

.applyFilterButton:hover {
  background-color: #4758EB; /* Darker background on hover */
}


.brandInputContainer {
  margin-bottom: 20px;
}

.brandInput {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.genderInputContainer {
  margin-bottom: 20px;
}

.genderInput {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.warningMessage {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}


.inputContainer {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.inputContainer label {
  width: 150px; /* Set a fixed width for the label */
  margin-right: 10px;
}

.inputField {
  flex: 1; /* Allow the input field to take the remaining space */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.languageAndColorContainer {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.backButton {
  background-color: #d0d0d0; /* Same background color as the applyFilterButton */
  color: #ffffff; /* White icon color */
  border: none;
  padding: 10px;
  border-radius: 50%; /* Make the button circular */
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px; /* Set width to make it a circle */
  height: 40px; /* Set height to make it a circle */
}

.backButton:hover {
  background-color: #7c7c7c; /* Darker background on hover */
}

.countOfProductsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  padding: 15px 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
}

.countOfProducts {
  font-size: 18px;
  font-family: 'Lexend Deca', sans-serif;
  color: #292B3D;
  font-weight: 600;
  text-align: center;
}


/* Specific styles for the Attribute Audit table */
.attributeAuditTable {
  width: 100%; /* Make the table use the full width */
  border-collapse: collapse;
  text-align: left; /* Left align the text in table cells */
  table-layout: auto; /* Allow the table to adjust column width based on content */
}

.attributeAuditTable th,
.attributeAuditTable td {
  padding: 10px;
  border: 1px solid #ddd;
  color: #292B3D;
}


.attributeAuditTable th {
  padding: 10px;
  border: 1px solid #ddd;
  background-color: #f8f8f8; /* Override any existing background color */
  color: #292B3D;
}

.attributeAuditTable .attributeColumn {
  width: 25%; /* Attribute column takes 25% of the width */

}

.attributeAuditTable .smallColumn {
  width: 15%; /* The existing width setting */
  text-align: center; /* Center text horizontally */
  font-weight: bold;
}

.attributeAuditTable .frequentColumn {
  width: 45%; /* Top 3 Most Frequent column takes 45% of the width */
  text-align: left;
  position: relative; /* Allows positioning the button to the right */
  white-space: normal; /* Allow line breaks */
}

.attributeAuditTable .frequentValue {
  margin-bottom: 5px; /* Adds some space between top frequent values */
  word-wrap: break-word; /* Breaks long words to fit within the column */
}

.attributeAuditTable .moreButton {
  background-color: #848EE0; /* Same background color as the backButton */
  color: #ffffff; /* White icon color */
  border: none;
  border-radius: 50%; /* Make the button circular */
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px; /* Set width to make it a circle */
  height: 40px; /* Set height to make it a circle */
  position: absolute; /* Position the button absolutely */
  right: 20px; /* Align the button to the right */
  top: 50%; /* Vertically center the button */
  transform: translateY(-50%); /* Adjust for proper centering */
}

.attributeAuditTable .moreButton:hover {
  background-color: #4758EB; /* Darker background on hover */
}
.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popupContent {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 80%;
  width: 90%;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
  font-family: 'Lexend Deca', sans-serif;
  color: #292B3D;
}

.popupContent h3 {
  margin-top: 0;
  font-size: 18px;
  color: #4758EB;
  text-align: center;
}

.popupContent p {
  text-align: center; /* Centering the paragraph */
}

.popupTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.popupTable th,
.popupTable td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.popupTable th {
  background-color: #f5f5f5;
  color: #4758EB;
  font-size: 14px;
}

.popupTable td {
  font-size: 14px;
  color: #292B3D;
}

.popupTable tr:hover {
  background-color: #f0f0f0;
}

.closePopupButton {
  background-color: #848EE0;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: 'Lexend Deca', sans-serif;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.closePopupButton:hover {
  background-color: #6577C7;
}

.closeIconButton {
  position: sticky; /* Make the button sticky */
  top: 10px; /* Align it to the top of the container */
  background: none;
  border: none;
  color: #6577C7;
  font-size: 20px;
  cursor: pointer;
  padding: 0;
  margin-left: auto; /* Push it to the right */
}

.closeIconButton:hover {
  color: #4758EB;
}



.auditHeader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px; /* Space between the dropdowns */
  flex-wrap: wrap; /* Ensure they wrap if there's not enough space */
}


.attributeDropdown {
  margin-left: 32px;
  flex-shrink: 0; /* Prevent the dropdown from shrinking */
  min-width: 200px;
}

.customSelect {
  flex-shrink: 0; /* Prevent the multi-select from shrinking */
  min-width: 500px;
  max-width: 800px; /* Limit the maximum width */

}

.buttonContainer {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between the buttons */
}



.downloadButton {
  background-color: #848EE0; /* Same background color as the backButton */
  color: #ffffff; /* White icon color */
  border: none;
  padding: 10px;
  border-radius: 50%; /* Make the button circular */
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px; /* Set width to make it a circle */
  height: 40px; /* Set height to make it a circle */
}

.downloadButton:disabled {
  background-color: #d0d0d0; /* Greyed out when disabled */
  cursor: not-allowed;
}

.downloadButton:hover:not(:disabled) {
  background-color: #4758EB; /* Darker background on hover, only when not disabled */
}


.smallTableButton {
  background-color: #848EE0; /* Same background color */
  color: #ffffff; /* White icon color */
  border: none;
  padding: 2px; /* Reduced padding for a smaller size */
  border-radius: 50%; /* Keep the button circular */
  cursor: pointer;
  transition: background-color 0.3s;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 20px; /* Smaller width */
  height: 20px; /* Smaller height */
  margin-left: 5px; /* Add space between the text and the button */
  font-size: 10px; /* Smaller font size */
}

.smallTableButton:disabled {
  background-color: #d0d0d0; /* Greyed out when disabled */
  cursor: not-allowed;
}

.smallTableButton:hover:not(:disabled) {
  background-color: #4758EB; /* Darker background on hover, only when not disabled */
}
